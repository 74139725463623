import xhr from './xhr/';
/**
 * 预警管理相关的 API
 */
class LinghuoService {
	// 增加服务
	addServices(data) {
		return xhr({
			method: 'post',
			url: '/linghuo/add',
			params: data,
		});
	}

	// 服务详情
	detailServices(data) {
		return xhr({
			method: 'get',
			url: '/linghuo/detail',
			params: data,
		});
	}

	// 促进服务搜索首页
	indexServices(data) {
		return xhr({
			method: 'get',
			url: '/linghuo/index',
			params: data,
		});
	}

	// 服务搜索
	listServices(data) {
		return xhr({
			method: 'get',
			url: '/linghuo/list',
			params: data,
		});
	}

	// 服务搜索
	deleteServices(data) {
		return xhr({
			method: 'post',
			url: '/linghuo/delete',
			params: data,
		});
	}
}

// 实例化后导出，全局单例
export default new LinghuoService();
